<template>
    <modal ref="modalInfoCancelado" titulo="Información pedido cancelado" tamano="modal" no-cancelar no-aceptar>
        <div v-if="motivo" class="row mx-0 mb-4 justify-center">
            <div class="col-12">
                <p class="text-general">Motivo:</p>
                <p class="text-general2">{{ motivo }}</p>
            </div>
        </div>
        <div v-if="justificacion" class="row mx-0 justify-center">
            <div class="col-12">
                <p class="text-general">Justificación:</p>
                <p class="text-general2">{{ justificacion }}</p>
            </div>
        </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex'
export default {
    data(){
        return{
            motivo: '',
            justificacion: '',
        }
    },
    computed:{
    },
    methods: {
        toggle(motivo, justificacion){
            this.motivo = motivo;
            this.justificacion = justificacion;
            this.$refs.modalInfoCancelado.toggle()
        },
    }
}
</script>
